// src/api/axiosService.ts
import axios, { AxiosInstance, InternalAxiosRequestConfig, AxiosResponse, AxiosError, CancelTokenSource } from 'axios';
import { useAccountStore } from '@/stores/account'; // Adjust for Pinia
import Setting from '@/setting';
import util from '@/libs/util';
import AccountApi from '@/api/account';

interface ErrorResponseData {
    message?: string;
}

interface PendingRequests {
    [key: string]: CancelTokenSource;
}

export interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
    cancelPrevious?: boolean;
    contentType?: string;
}

let pendingRequests: PendingRequests = {};

const service: AxiosInstance = axios.create({
    baseURL: Setting.apiBaseURL,
    timeout: 60000, // Request timeout
    withCredentials: true // Send cookies when cross-domain requests
});

service.interceptors.request.use(
    async (config: CustomAxiosRequestConfig) => {
        if (!config.url || !config.method) return config;
        config.headers = config.headers || {};
        // Cancel previous request if it exists
        const source = axios.CancelToken.source();
        const requestKey: string = config.url && config.method ? `${config.url}+${config.method}` : '';

        // Check if the config specifies to cancel existing requests
        const cancelPrevious = config.cancelPrevious || false;

        // If cancelPrevious is true, cancel previous request if it exists
        if (cancelPrevious) {
            pendingRequests[requestKey]?.cancel('Canceled due to new request');
        }
        pendingRequests[requestKey] = source;
        config.cancelToken = source.token;

        const contentType = config.contentType || '';
        if (contentType) {
            config.headers['Content-Type'] = contentType;
        }

        const accountStore = useAccountStore();
        const token = accountStore.getToken;
        // const token = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiZGF0YXBvcnRhbC1hcHAiXSwiZXhwIjoxNzE5NDA0MDI2LCJpYXQiOjE3MTY4MTIwMjYsImlzcyI6ImRhdGFwb3J0YWwiLCJuYmYiOjE3MTY4MTIwMjYsInN1YiI6IjNiMWM4OGM0LTI4NzMtNDM2Mi1hNTY4LTJjZDQ1NGY1NDEyMiJ9.C-XwNF8hjfeuiQh5qb2UHgDlDj3_i1lTGxzuSGUtd7E';
        if (token && config.url !== 'mobile/v1.0/member_sessions/mobile_code' && config.url !== 'mobile/v1.0/member_sessions/mobile_login') {
            config.headers.Authorization = `${token}`;
        }
        // let csrfToken = accountStore.getCSRFToken;
        // if (!csrfToken && config.url !== 'v1.0/') {
        //     await AccountApi.GetCSRFToken();
        //     csrfToken = accountStore.getCSRFToken;
        // }
        // config.headers['X-Csrf-Token'] = csrfToken;

        return config;
    },
    (error: AxiosError) => Promise.reject(error)
);

service.interceptors.response.use(
    (response: AxiosResponse) => {
        // Remove the request from pendingRequests
        const requestKey: string = response.config.url && response.config.method ? `${response.config.url}+${response.config.method}` : '';
        delete pendingRequests[requestKey];

        // Handle CSRF token
        // const csrfToken = response.headers['x-csrf-token'];
        // if (csrfToken) {
        //     const accountStore = useAccountStore();
        //     accountStore.setCSRFToken(csrfToken);
        // }
        const authorizationToken = response.headers.authorization;
        if (authorizationToken && (response.config.url === 'mobile/v1.0/member_sessions/mobile_login' || response.config.url === 'mobile/v1.0/member_sessions')) {
            const accountStore = useAccountStore();
            accountStore.setToken(authorizationToken);
        }
        // Example response handling
        const dataAxios = response.data;
        if (dataAxios && 'code' in dataAxios) {
            switch (dataAxios.code) {
                case 0:
                    return dataAxios.data;
                case 'xxx':
                    throw new Error(`[ code: xxx ] ${dataAxios.msg}: ${response.config.url}`);
                default:
                    throw new Error(`${dataAxios.msg}: ${response.config.url}`);
            }
        }
        return dataAxios;
    },
    (error: AxiosError) => {
        if (error.config && error.config.url && error.config.method) {
            const requestKey: string = `${error.config.url}+${error.config.method}`;
            delete pendingRequests[requestKey];
        }
        if (axios.isCancel(error)) {
            console.log('Request canceled:', error.message);
        } else {
            handleErrorResponse(error);
        }
        return Promise.reject(error);
    }
);

function handleErrorResponse(error: AxiosError): void {
    const status = error.response?.status;
    const errorMessage = (error.response?.data as ErrorResponseData)?.message || error.message;
    switch (status) {
        case 400:
            error.message = `Bad Request: ${errorMessage}`;
            break;
        // Handle other status codes as needed
    }
    if (import.meta.env.DEV) {
        util.log.error('>>>>>> Axios Error >>>>>>');
        console.error(error);
    }
}

export function cancelAllRequests(): void {
    Object.values(pendingRequests).forEach((source) => source.cancel('Logout: canceling pending requests'));
    pendingRequests = {};
}

export default service;
