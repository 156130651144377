// src/stores/account.ts
import { defineStore } from 'pinia';
import AccountApi from '@/api/account';
import router from '@/router'; // Assuming you have a router setup
import { memberOcId, memberOcOaIds } from '@/libs/oc_oa'
import OneSignal from 'onesignal-cordova-plugin';
import { Capacitor } from '@capacitor/core';

export interface User {
    // Define user properties here
    id: string;
    mobile: string;
    countryCode: string;
    firstName: string;
    lastName: string;
    name: string;
    avatar: string;
    avatarId?: string;
    access: Array<string>;
    timezone?: string;
}

interface LoginPayload {
    mobileCountryCode: string;
    mobile: string;
    verifyCode: string;
    remember: boolean;
}

interface RegisterPayload {
    mobileCountryCode: string;
    mobile: string;
    verifyCode: string;
}

interface UpdatePayload {
    mobileCountryCode: string;
    mobile: string;
    verifyCode: string;
    firstName: string;
    lastName: string;
    name: string;
    avatarId: string;
}

export const useAccountStore = defineStore({
    id: 'account',
    state: () => {
        return {
            csrfToken: '',
            token: '',
            user: null as User | null,
            isAuthenticated: false
        }
    },
    actions: {
        login({ mobileCountryCode, mobile, verifyCode, remember }: LoginPayload) {
            return new Promise<void>((resolve, reject) => {
                AccountApi.AccountLogin({ customer_domain: 'flowflicker', mobile_country_code: mobileCountryCode, mobile, code: verifyCode, remember })
                    .then(async () => {
                        this.isAuthenticated = true;
                        await this.getUserInfo();
                        resolve();
                    })
                    .catch(error => {
                        this.user = null;
                        this.token = '';
                        reject(error);
                    });
            });
        },
        logout() {
            // Clear user data and authenticated state
            this.user = null;
            this.token = '';
            this.isAuthenticated = false;
            this.csrfToken = '';
            router.push({ name: 'login' });
            // Optionally, refresh the page to clear any in-memory data
            window.location.reload();
        },
        register({ mobileCountryCode, mobile, verifyCode }: RegisterPayload) {
            const oeavs = {
                [this.columnNameForOaId(memberOcOaIds.mobile_country_code)]: mobileCountryCode,
                [this.columnNameForOaId(memberOcOaIds.mobile)]: mobile
            }

            return new Promise<void>((resolve, reject) => {
                AccountApi.AccountRegister(memberOcId, {
                    object_entity_attribute_values: oeavs,
                    code: verifyCode
                }).then(async res => {
                    resolve();
                }).catch(error => {
                    this.user = null;
                    this.token = '';
                    reject(error);
                });
            });
        },
        update({ mobileCountryCode, mobile, verifyCode, firstName, lastName, name, avatarId }: UpdatePayload) {
            const oeavs = {
                [this.columnNameForOaId(memberOcOaIds.mobile_country_code)]: mobileCountryCode,
                [this.columnNameForOaId(memberOcOaIds.mobile)]: mobile,
                [this.columnNameForOaId(memberOcOaIds.first_name)]: firstName,
                [this.columnNameForOaId(memberOcOaIds.last_name)]: lastName,
                [this.columnNameForOaId(memberOcOaIds.name)]: name,
                [this.columnNameForOaId(memberOcOaIds.avatar)]: [avatarId],
            }

            return new Promise<void>((resolve, reject) => {
                AccountApi.AccountUpdate(memberOcId, {
                    object_entity_attribute_values: oeavs,
                    code: verifyCode
                }).then(async res => {
                    this.token = res.data.token;
                    await this.getUserInfo();
                    resolve();
                })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        updateAvatar(id: string, url?: string) {
            const oldAvatar = this.getUser?.avatar || '';
            const oldAvatarId = this.getUser?.avatarId || '';

            const attachmentIdsToUnlink: string[] = [];
            if (oldAvatarId) {
                attachmentIdsToUnlink.push(oldAvatarId);
            }

            if (this.user) {
                AccountApi.AccountUpdate(this.user.id, {
                    object_entity_attribute_values: { [this.columnNameForOaId(memberOcOaIds.avatar)]: [id] },
                    attachment_ids_to_link: [id],
                    attachment_ids_to_unlink: attachmentIdsToUnlink
                }).then((res) => {
                    if (url) {
                        this.setAvatar(id, url);
                    } else {
                        this.getUserInfo();
                    }
                }).catch(error => {
                    this.setAvatar(oldAvatarId, oldAvatar);
                });
            }
        },
        updateUserName(name: string) {
            const oldName = this.getUser?.name || '';
            this.setName(name);
            return new Promise<void>((resolve, reject) => {
                if (this.user) {
                    AccountApi.AccountUpdate(this.user.id, {
                        object_entity_attribute_values: { [this.columnNameForOaId(memberOcOaIds.name)]: name }
                    }).then(() => {
                        resolve();
                    }).catch(error => {
                        this.setName(oldName);
                        reject(error);
                    });
                } else {
                    reject('User invalid');
                }
            });
        },
        updateMobile(mobileCountryCode: string, mobile: string, verifyCode: string) {
            const oldCountryCode = this.getUser?.countryCode || '';
            const oldMobile = this.getUser?.mobile || '';
            this.setMobile(mobileCountryCode, mobile);
            return new Promise<void>((resolve, reject) => {
                if (this.user) {
                    AccountApi.AccountUpdate(this.user.id, {
                        object_entity_attribute_values: {
                            [this.columnNameForOaId(memberOcOaIds.mobile_country_code)]: mobileCountryCode,
                            [this.columnNameForOaId(memberOcOaIds.mobile)]: mobile
                        },
                        code: verifyCode
                    }).then(() => {
                        resolve();
                    }).catch(error => {
                        this.setMobile(oldCountryCode, oldMobile);
                        reject(error);
                    });
                } else {
                    reject('User invalid');
                }
            });
        },
        async setImageFromUrl(imageUrl: string) {
            try {
                const response = await fetch(imageUrl);
                const blob = await response.blob();
                const base64Image = await this.blobToBase64(blob);
                if (this.user) {
                    this.user.avatar = base64Image as string;
                }
            } catch (error) {
                console.error('Error converting image URL to base64:', error);
            }
        },
        getUserInfo() {
            return new Promise<void>((resolve, reject) => {
                // Fetch and update user info
                AccountApi.GetUserInfo().then(res => {
                    let avatarUrl = '';
                    let avatarId = null;
                    const avatarArray = res.data[0].attributes['oa_' + memberOcOaIds.avatar.replaceAll('-', '_')];
                    if (Array.isArray(avatarArray) && avatarArray.length) {
                        avatarId = avatarArray[0];
                        if (avatarId) {
                            const avatarFile = res.data[0].relationships.attachments.data[avatarId];
                            if (avatarFile) {
                                avatarUrl = avatarFile.attributes.storage_url;
                            }
                        }
                    }

                    if (avatarId) {
                        const avatarFile = res.data[0].relationships.attachments.data[avatarId];
                        if (avatarFile) {
                            avatarUrl = avatarFile.attributes.storage_url;
                        }
                    }

                    this.user = {
                        // Define user properties here
                        id: res.data[0].id,
                        mobile: res.data[0].attributes['oa_' + memberOcOaIds.mobile.replaceAll('-', '_')],
                        countryCode: res.data[0].attributes['oa_' + memberOcOaIds.mobile_country_code.replaceAll('-', '_')],
                        firstName: res.data[0].attributes['oa_' + memberOcOaIds.first_name.replaceAll('-', '_')],
                        lastName: res.data[0].attributes['oa_' + memberOcOaIds.last_name.replaceAll('-', '_')],
                        name: res.data[0].attributes['oa_' + memberOcOaIds.name.replaceAll('-', '_')],
                        avatarId: avatarId,
                        avatar: '',
                        access: ['Member']
                    };
                    if (avatarUrl) {
                        this.setImageFromUrl(avatarUrl);
                    }
                    if (Capacitor.getPlatform() !== 'web') {
                        OneSignal.login(this.user.id);
                    }
                    resolve();
                }).catch(error => {
                    this.user = null;
                    reject(error);
                });
            });
        },
        deleteAccount() {
            return new Promise<void>((resolve, reject) => {
                // Fetch and update user info
                if (this.user) {
                    AccountApi.DeleteAccount(this.user.id).then(() => {
                        this.logout();
                        resolve();
                    }).catch((e) => {
                        reject(e);
                    });
                } else {
                    reject('User invalid');
                }
            });
        },
        columnNameForOaId(oaId: string) {
            return `oa_${oaId.replaceAll('-', '_')}`
        },
        setCSRFToken(token: string) {
            this.csrfToken = token;
        },
        setToken(token: string) {
            this.token = token;
        },
        setName(name: string) {
            if (this.user) {
                this.user.name = name;
            }
        },
        setMobile(mobileCountryCode: string, mobile: string) {
            if (this.user) {
                this.user.countryCode = mobileCountryCode;
                this.user.mobile = mobile;
            }
        },
        setAvatar(avatarId: string, avatar: string) {
            if (this.user) {
                this.user.avatar = avatar;
                this.user.avatarId = avatarId;
            }
        },
        blobToBase64(blob: Blob) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        },
        setUserTimezone(timezone: string) {
            if (this.user) {
                this.user.timezone = timezone;
                AccountApi.AccountUpdate(this.user.id, {
                    object_entity_attribute_values: { [this.columnNameForOaId(memberOcOaIds.timezone)]: timezone }
                }).then(() => {
                    // Do something
                }).catch((e) => {
                    console.error('Error updating user timezone:', e);
                });
            }
        }
    },
    getters: {
        getToken(): string {
            return this.token;
        },
        getUser(): User | null {
            return this.user;
        },
        getCSRFToken(): string {
            return this.csrfToken;
        },
        getIsAuthenticated(): boolean {
            return this.isAuthenticated;
        }
    },
    persist: {
        enabled: true, // enable the plugin
        strategies: [
            { storage: localStorage, paths: ['csrfToken', 'token', 'user', 'isAuthenticated'] }
        ]
    }
});
