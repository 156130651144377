import { createApp, ref } from 'vue'
import App from './App.vue'
import plugins from '@/plugins';

import { IonicVue } from '@ionic/vue';
import { createPinia } from 'pinia';
import piniaPersist from 'pinia-plugin-persist';
import router from './router';
import { i18n } from '@/locales';
import { Capacitor } from '@capacitor/core';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import VueSocialSharing from 'vue-social-sharing'

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { RecycleScroller } from 'vue-virtual-scroller'

/* Theme variables */
import './theme/variables.css';

/* Ionic PWA Elements */
import { defineCustomElements } from '@ionic/pwa-elements/loader';
defineCustomElements(window);

import OneSignal from 'onesignal-cordova-plugin';
import { useVideoStore, Video } from './stores/videos';
import { add } from 'lodash';
import { SplashScreen } from '@capacitor/splash-screen';

const pinia = createPinia();
pinia.use(piniaPersist);

const app = createApp(App)
    .use(pinia)
    .use(IonicVue);

app.use(router);

app.use(plugins);

app.use(i18n);

app.use(VueSocialSharing);

app.component('RecycleScroller', RecycleScroller)

if (Capacitor.getPlatform() !== 'web') {
    OneSignal.initialize("c341a261-8106-4107-aed7-4bcd1af2ed5b");
    OneSignal.Notifications.requestPermission();
    setupNotificationListener();
}

function setupNotificationListener() {
    if (Capacitor.getPlatform() !== 'web') {
        OneSignal.Notifications.addEventListener('click', (notification) => {
            const additionalData = notification.notification.additionalData as { notificationType: string, dataId: string };
            if (additionalData) {
                const notificationType = additionalData.notificationType;
                const dataId = additionalData.dataId;
                if (notificationType === 'Reminder') {
                    router.push({
                        name: 'ReminderVideo',
                        params: { id: dataId }
                    });
                }
            }
        });
    }
}

router.isReady().then(() => {
    app.mount('#app');
    if (Capacitor.getPlatform() !== 'web') {
        setTimeout(() => {
            SplashScreen.hide();
        }, 6000);
    }
});