import { deleteObjectEntity, patchObjectEntity } from '@/libs/object_entities';
import { memberOcId, memberOcOaIds } from '@/libs/oc_oa';
import request from '@/plugins/request';
import { useAccountStore } from '@/stores/account';

const pre = 'mobile/v1.0/';

export default class AccountApi {
    static GetCSRFToken() {
        return request({
            url: 'v1.0/',
            method: 'get'
        });
    }

    static AccountLogin(data: object) {
        data = { ...data, customer_domain: 'flowflicker' }
        return request({
            url: `${pre}member_sessions/mobile_login`,
            method: 'post',
            data
        });
    }

    static AccountRegister(memberOcId: string, data: object) {
        return request({
            url: `${pre}public/member`,
            method: 'post',
            data: {
                customer_domain: 'flowflicker',
                ...data
            }
        });
    }

    static GetVerificationCode(data: { mobile_country_code: string; mobile: string; customer_domain: string }) {
        data = { ...data, customer_domain: 'flowflicker' }
        return request({
            url: `${pre}member_sessions/mobile_code`,
            method: 'post',
            data
        });
    }

    static GetUserInfo() {
        return request({
            url: `${pre}member_sessions`,
            method: 'get'
        });
    }

    static UpdateAccount(name: string, firstName: string = '', lastName: string = '') {
        const accountStore = useAccountStore();

        const payload = {
            [memberOcOaIds.name]: name,
            [memberOcOaIds.first_name]: firstName,
            [memberOcOaIds.last_name]: lastName
        }

        const attachmentIdsToLink: string[] = []
        const attachmentIdsToUnlink: string[] = []

        return patchObjectEntity(
            memberOcId,
            accountStore.getUser?.id || '',
            payload,
            attachmentIdsToLink,
            attachmentIdsToUnlink
        )
    }

    /**
     * New Update Account Details API
     * @param id 
     * @param data 
     * @returns 
     */
    static AccountUpdate(id: string, data: object) {
        return request({
            url: `${pre}members/${id}`,
            method: 'patch',
            data
        });
    }

    static UpdateAccountAvatar(avatarId: string, oldAvatarId: string) {
        const accountStore = useAccountStore();

        const payload = {}

        const attachmentIdsToLink: string[] = []
        if (avatarId !== '') {
            attachmentIdsToLink.push(avatarId)
            payload[memberOcOaIds.avatar] = [avatarId]
        }

        const attachmentIdsToUnlink: string[] = []
        if (oldAvatarId !== '') {
            attachmentIdsToUnlink.push(oldAvatarId)
        }

        if (oldAvatarId !== '' && avatarId === '') {
            payload[memberOcOaIds.avatar] = []
        }

        return patchObjectEntity(
            memberOcId,
            accountStore.getUser?.id || '',
            payload,
            attachmentIdsToLink,
            attachmentIdsToUnlink
        )
    }

    static AccountLogout() {
        return request({
            url: 'logout',
            method: 'post'
        });
    }

    static DeleteAccount(accountId: string) {
        return deleteObjectEntity(memberOcId, accountId)
    }
}
