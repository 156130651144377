import { get } from 'lodash'
import request, { CustomAxiosRequestConfig } from '@/plugins/request';

async function getObjectEntities(
    objectClassId: string,
    page: number,
    size: number,
    objectAttributeIds: Array<string>,
    _ocIdToIdLookupOaId: { [x: string]: string; },
    searchQuery: { object_class_id: any; search_query_groups: Array<object>; sort_query_group: object; },
    cancelPrevious = true
) {
    const searchQueryPayload = searchQuery || {
        object_class_id: objectClassId,
        search_query_groups: [],
        sort_query_group: {}
    }
    const config: CustomAxiosRequestConfig = {
        url: `mobile/v1.0/object_entities/${objectClassId}/query`,
        method: 'POST',
        data: {
            search_query: searchQueryPayload,
            page,
            size,
            object_attribute_ids: objectAttributeIds
        },
        cancelPrevious // Add the cancelPrevious property here
    };
    return await request(config)
        .then((response) => {
            const data = response.data
            const meta = response.meta
            let objectEntities: any[] = []
            for (const value in data) {
                const newObjectEntity = data[value]

                objectEntities = objectEntities.concat([newObjectEntity])
            }
            const included = response.included
            return {
                objectEntities,
                referencedObjectEntityHash: included,
                total: meta.total
            }
        }).catch(err => {
            console.error(err);
            return {
                objectEntities: [],
                referencedObjectEntityHash: {},
                total: 0
            }
        })
}

async function getObjectEntity(
    objectClassId: string,
    _ocIdToIdLookupOa: { [x: string]: any; },
    id: string
) {
    return await request(
        {
            url: `mobile/v1.0/object_entities/${objectClassId}/${id}`,
            method: 'GET'
        }
    )
        .then((response) => {
            const data = response.data
            const objectEntity = data[0]
            const included = response.included
            return {
                objectEntity,
                referencedObjectEntityHash: included
            }
        })
}

async function postObjectEntity(
    objectClassId,
    objectEntityAttributeValues,
    attachmentIdsToLink,
    cancelPrevious = true
) {
    const config: CustomAxiosRequestConfig = {
        url: `mobile/v1.0/object_entities/${objectClassId}`,
        method: 'POST',
        data: {
            object_entity_attribute_values: objectEntityAttributeValues,
            attachment_ids_to_link: attachmentIdsToLink
        },
        cancelPrevious
    };
    return await request(config)
        .then((response) => {
            const data = response.data[0]
            const objectEntity = {
                id: data.id,
                type: 'ObjectEntity'
            }
            return objectEntity
        })
}

async function patchObjectEntity(
    objectClassId: string,
    id: string,
    objectEntityAttributeValues: any,
    attachmentIdsToLink: any[],
    atachmentIdsToUnlink: any[]
) {
    delete objectEntityAttributeValues.created_at
    delete objectEntityAttributeValues.updated_at
    return await request(
        {
            url: `mobile/v1.0/object_entities/${objectClassId}/${id}`,
            method: 'PATCH',
            data: {
                object_entity_attribute_values: objectEntityAttributeValues,
                attachment_ids_to_link: attachmentIdsToLink,
                attachment_ids_to_unlink: atachmentIdsToUnlink
            }
        })
        .then((response) => {
            const data = response.data[0]
            const objectEntity = {
                id: data.id,
                type: 'ObjectEntity'
            }
            return objectEntity
        })
}

async function deleteObjectEntity(
    objectClassId: string,
    id: string
) {
    return await request(
        { url: `mobile/v1.0/object_entities/${objectClassId}/${id}`, method: 'DELETE' })
        .then((response) => {
            const data = response.data[0]
            const objectEntity = {
                id: data.id,
                type: 'ObjectEntity'
            }
            return objectEntity
        })
}

async function deleteObjectEntitiesBySearchQuery(
    objectClassId: string,
    searchQuery: { object_class_id: any; search_query_groups: Array<object>; },
    cancelPrevious = true
) {
    const config: CustomAxiosRequestConfig = {
        url: `mobile/v1.0/object_entities/${objectClassId}`,
        method: 'DELETE',
        data: {
            search_query: searchQuery
        },
        cancelPrevious
    };
    return await request(config)
        .then((response) => {
            const data = response.data
            return data
        })
}

async function getPublicObjectEntities(
    objectClassId: string,
    page: number,
    size: number,
    objectAttributeIds: Array<string>,
    _ocIdToIdLookupOaId: { [x: string]: string; },
    searchQuery: { object_class_id: any; search_query_groups: Array<object>; sort_query_group: object; }
) {
    const searchQueryPayload = searchQuery || {
        object_class_id: objectClassId,
        search_query_groups: {},
        sort_query_group: {}
    }
    return await request(
        {
            url: `/mobile/v1.0/public/object_entities/${objectClassId}/query`,
            method: 'POST',
            data: {
                search_query: searchQueryPayload,
                page,
                size,
                object_attribute_ids: objectAttributeIds,
                customer_domain: 'flowflicker'
            }
        })
        .then((response) => {
            const data = response.data
            const meta = response.meta
            let objectEntities: any[] = []
            for (const value in data) {
                const newObjectEntity = data[value]

                objectEntities = objectEntities.concat([newObjectEntity])
            }
            const included = response.data.included
            return {
                objectEntities,
                referencedObjectEntityHash: included,
                total: meta.total
            }
        }).catch(err => {
            console.error(err);
            return {
                objectEntities: [],
                referencedObjectEntityHash: {},
                total: 0
            }
        })
}

export {
    getObjectEntities,
    getObjectEntity,
    postObjectEntity,
    patchObjectEntity,
    deleteObjectEntity,
    deleteObjectEntitiesBySearchQuery,
    getPublicObjectEntities
}
