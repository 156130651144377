// locales/i18n.ts
import { createI18n } from 'vue-i18n';
import enUS from './en/all.json';

export const i18n = createI18n({
    legacy: false,
    locale: 'en-US',
    messages: {
      'en-US': enUS,
    },
})