<template>
    <ion-app>
        <ion-router-outlet />
    </ion-app>
</template>

<script setup lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import 'v3-infinite-loading/lib/style.css';
import { onMounted } from 'vue';
import { useAccountStore } from './stores/account';

onMounted(async () => {
    const accountStore = useAccountStore();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    accountStore.setUserTimezone(timezone);
    const user = accountStore.getUser;
    if (user) {
        accountStore.getUserInfo().catch((err) => {
            accountStore.logout();
        });
    }
});
</script>

<style lang="scss">
.ion-content-scroll-host {
    width: 100%;
}

ion-tabs {
    .tabs-inner {
        overflow: hidden;
    }
}
</style>
