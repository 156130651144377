const videoOcId = 'a2b9b346-89a2-4cff-9640-14f8dd8c5c3a'
const videoOcOaIds = {
    id: 'c7a8c2f2-ff28-40d8-91f5-e1cf9a520974',
    file: '87635f0d-6c12-4994-8709-0d26eef2ac92',
    file_thumbnail: 'f6f5a3f7-7b67-4445-88cb-c6880b7ed185',
    name: '4f9bd428-e9fd-45f2-8990-1a8d8f292be3',
    description: '489a10c9-3f7e-4005-9341-2bc37d8d7390',
    created_at: '6922d11f-ce63-4eea-bb1b-491b365fea42',
    has_bookmarked: 'b0313ac1-4322-43d7-9fc0-04cd3582ad38',
    has_commented: '5b76fb3d-b208-475a-ad1b-19833f1bf5f6',
    has_liked: '5e2c7098-84ca-4714-aad1-d98cd00b53a7',
    has_shared: '90f68c26-944a-4b31-aeab-47a68006c5f5',
    has_watched: '6d3214e4-2337-42cb-86ed-2a7595ebbdb7',
    bookmark_ids: 'bf10dedd-fc25-4250-93b4-b519bfe7663b',
    like_ids: 'e9003616-c38a-4e66-8c31-e6667b302d50',
    bookmark_count: 'ebadf3d8-b468-40a7-8fae-9a535dbb1b14',
    comment_count: '2f649485-f660-4d74-9da8-0c4f49a24fd1',
    like_count: 'bceb39fd-6baa-486f-80d5-a1963d439a98',
    share_count: 'eb33ad52-5240-45c0-a657-e135bf71b25c',
    watched_count: '509b01a0-ae98-4f2c-afe8-00a61ab25cdd',
    last_watched_at: '46f34d24-21c5-4518-bc21-f89ab98a6ed4',
    last_liked_at: '234c0949-e9a2-4112-8f96-67fe84d70950',
    member_collection_ids: 'c237bcc8-e264-44fa-ab0c-ef60b7c94160',
    has_reminder: '61f1f7ba-8b3c-42e0-ab1d-988aae96217a',
    last_reminder_created_at: 'cb46e2c3-4172-43e6-a2aa-9889cadbaa64'
}

const ocIdToIdLookupOa = {
    'a2b9b346-89a2-4cff-9640-14f8dd8c5c3a': 'c7a8c2f2-ff28-40d8-91f5-e1cf9a520974', // video
    'a0d77ba5-71cf-4055-8989-efa5e5ea88a8': 'fcfda483-5475-40ea-a36e-6fe407bb2f89', // bookmark
    'e76fc109-8a2c-4fc0-a210-275913e3024e': '09741dcd-9df5-43be-b3bc-1c7d84712f10', // like
    'f61e3913-ed5b-4d07-a44e-152f7f809337': 'df85f0e1-1edc-403b-953d-81429439ac76', // member
    '74be1855-2235-48a6-b414-330f8e49d037': 'a5caffac-991f-4df2-9b4c-002cd019d052', // collection
    'ac55b1e6-32cf-4a39-9e12-09029c8712f0': '456ae146-bd3e-401b-a43b-4577cba38769', // watched
    'ac7da386-f75a-4d0d-b16e-78d860e787d8': '0d756804-57c3-4986-8107-31860b1885c7', // comment
    'fbcf4925-704b-4af7-926d-6ff6976b3380': '6c3f077f-5680-48b0-b551-695e4afa4607', // reminder
    '63d5d08c-e315-4ad6-87aa-81ebd14482c1': '36938a55-86a1-434b-aba3-dbcb26911b21' // share
}

const memberOcId = 'f61e3913-ed5b-4d07-a44e-152f7f809337'
const memberOcOaIds = {
    mobile_country_code: 'dc25316c-4dc7-42da-b2bc-c560c8a22145',
    mobile: '8e7d0ac5-832a-439b-80b4-a80fd060fb14',
    name: '40d85f67-3b1f-4ed6-910c-9de93fafb02e',
    first_name: '499656c8-e29f-42dc-9251-f4e0cd11ed9e',
    last_name: '386e2b9a-f5e6-4f44-a4e6-bc4d758928a7',
    avatar: '740fe541-9100-49f5-ad8d-f0b619ad6a08',
    timezone: 'b33a4308-e7fe-4193-836d-ee4fe308d112'
}

const likedOcId = 'e76fc109-8a2c-4fc0-a210-275913e3024e'
const likedOcOaIds = {
    id: '09741dcd-9df5-43be-b3bc-1c7d84712f10',
    video_id: 'd459ac30-0936-4178-978a-8a2a32258b7c',
    name: 'c48d335b-8431-4097-a68e-f6e192e792ef',
    member_id: '3d3e2629-7d48-4994-8abe-a237842cfb7c',
    created_at: 'f27ebd67-4d59-425d-98a6-0501a5a86ec6'
}

const watchedOcId = 'ac55b1e6-32cf-4a39-9e12-09029c8712f0'
const watchedOcOaIds = {
    id: '456ae146-bd3e-401b-a43b-4577cba38769',
    video_id: '49166bcc-2fe8-475e-b5d4-4f5fed347aee',
    created_at: '99bb1ba5-da72-49de-90f7-6d4b70d62a85',
    name: '9e1470db-da04-4d2a-a9e0-9b1a781beb86',
    member_id: 'c54a52ad-76ff-4418-a04a-0450267cb76d'
}

const folderOcId = '74be1855-2235-48a6-b414-330f8e49d037'
const folderOcOaIds = {
    id: 'a5caffac-991f-4df2-9b4c-002cd019d052',
    name: 'e3abfc7b-cd2a-47c0-a191-5fef41311455',
    member_id: '410928b5-c75a-4215-920d-1dc83a11b8ca',
    created_at: '7bca50b0-6119-4c62-ad70-3f1c747a004a',
    updated_at: '5cf8c661-180c-42bf-8ee3-0baa8d34ed6f',
    video_count: '2a905176-7f1b-4bf7-a414-de06a9c5f80e',
    last_video_added_at: '80ba7807-d2bd-4146-92a8-becb459f5b32',
}

const bookmarkOcId = 'a0d77ba5-71cf-4055-8989-efa5e5ea88a8'
const bookmarkOcOaIds = {
    id: 'fcfda483-5475-40ea-a36e-6fe407bb2f89',
    member_id: '9fa77047-e7a8-43b8-be66-4e157d4aeb57',
    video_id: '3e9d7ef0-998e-4a7e-9dca-4cedcf05360e',
    folder_id: 'e9b8036a-24ad-45a6-8e68-2cbc4550105a',
    created_at: 'ec83ae3a-eba4-4e5e-abb9-30b82b24d5e7',
    updated_at: '8dc218c3-a294-4476-bf31-5c9705e27b7a'
}

const commentOcId = 'ac7da386-f75a-4d0d-b16e-78d860e787d8'
const commentOcOaIds = {
    id: '0d756804-57c3-4986-8107-31860b1885c7',
    member_id: 'f4eaf4d5-e0ae-4681-87dc-07297d889462',
    member_name: '302e6def-d92e-4579-86ff-1b96ad5436c1',
    video_id: 'ea53cabb-eb4a-4397-9a52-51dec5dd5c51',
    content: 'b0c59f41-9706-4af6-b4fa-ccdb5d5b122a',
    created_at: '6a4db9e8-0f49-486f-addc-a94dfcac02f5',
    updated_at: '59bd350d-e44e-4d87-850d-ca2b10dbb190'
}

const shareOcId = '63d5d08c-e315-4ad6-87aa-81ebd14482c1'
const shareOcOaIds = {
    id: '36938a55-86a1-434b-aba3-dbcb26911b21',
    member_id: 'ab89fad7-b152-4a0c-beb4-2c94011d2b42',
    video_id: '4d80070a-3714-4ce4-b774-cb3c6c6e7809',
    created_at: '565533e0-45a4-4d0e-9669-798a82d45d46',
    updated_at: '06708149-715d-4a21-bdcc-291a1774fd7f'
}

const reminderOcId = 'fbcf4925-704b-4af7-926d-6ff6976b3380'
const reminderOcOaIds = {
    id: '6c3f077f-5680-48b0-b551-695e4afa4607',
    member_id: 'a402b5f5-177d-485c-a7fa-d234ce155776',
    video_id: 'c25ee316-4308-4baa-a4e5-60dba000cec7',
    created_at: 'aefca922-0101-4f2f-a369-bd191d01b2e6',
    updated_at: 'e9bc6c3a-5425-4b97-bdf2-db15b460fc1e',
    name: '57cef515-8dea-42fb-91dc-85e775a8dae7',
    time: 'd93ca21f-d0be-446d-b182-42b623c0ddad',
    frequency: 'c423d7b3-5495-4314-b534-e6ec43c46534',
    start_date: '6ddd06c8-f0eb-48e7-a5bf-81420c9ef6b4'
}

const reminderOcFrequencyPicklistOptions = {
    '5040fd7e-1155-47a1-a276-f0e2d4f891b0': 'Daily',
    'a353afa3-8d98-4644-9b02-0aedc98b55d3': 'Weekly',
    '3a494fdd-1dfe-47b1-9732-dccce8632765': 'Bi-weekly',
    '13c09282-3f6e-44d8-9614-4e8d70c8484b': 'Monthly'
}

const reminderOcTimePicklistOptions = {
    '3e0dde83-2abc-4f39-a57e-4d7b46f925ff': '00:00',
    'efabcbc4-3c40-4f95-9eec-8f61e50a1a4e': '00:30',
    'b490e0fb-b97e-41d8-b6ef-10935afeb4df': '01:00',
    'd7228a94-ca30-4e8b-931a-6d5f1eced3d4': '01:30',
    '0f6d518d-1d5d-4731-9ba8-b2005d472e51': '02:00',
    'bf393044-3db8-4f89-935c-2d0d3ed5ef54': '02:30',
    'ff046641-8bf0-4574-8b1b-c0fe3192ac8e': '03:00',
    '0f516b82-fa5a-4e49-b7fd-00cbc4b83d5d': '03:30',
    '0bcbe4c8-4e90-4553-a98d-2a8afb91cd3e': '04:00',
    '978c8a70-3009-4360-aa4d-01d285d54606': '04:30',
    '64faa44b-4475-43d5-97d6-e2458eadcf95': '05:00',
    'e16024fd-6237-4869-9d1a-e496191588dc': '05:30',
    '6ddfcbe1-caeb-43c6-bbe9-1faa5d25bd55': '06:00',
    '6347b6b3-1342-43d9-97b3-804adfe19fda': '06:30',
    'eb2f9111-464e-468b-97ea-3c3e1aae51ab': '07:00',
    '54e31dbf-b4b0-4bc2-a32f-e084848c77a4': '07:30',
    'f59a63a8-dde8-4452-8e67-aa17c4c97eca': '08:00',
    '68e73f5a-4482-4a19-acd8-2222eddf5d38': '08:30',
    '5d905404-700d-4780-84d9-29aa72b18695': '09:00',
    'e95ab552-0866-40cb-9ab7-29ac5cc84e6d': '09:30',
    '32fd7ab6-f6cb-4d2d-a85d-9063967a0edb': '10:00',
    '4a196fc5-d305-4b4a-8a6e-41921f57919e': '10:30',
    '80846e45-f093-4cab-bb17-b621d0912b9b': '11:00',
    'ce66429f-6228-4a1b-96b2-49ada30a4ac4': '11:30',
    '0638adcf-3975-4bec-b7e0-88b3612ae23e': '12:00',
    'f12d806d-57fa-4f9f-8681-a1f401054c17': '12:30',
    '91a34649-1fa8-443e-b645-4300e65d7727': '13:00',
    '0d6ecd98-51c8-46ff-b9d8-d9a5798c5ada': '13:30',
    '06699bb2-2924-46a0-ad7a-6c451991c4eb': '14:00',
    '56c1ab59-a9dc-4458-b3af-30c9b0d146e6': '14:30',
    '66b7590c-e584-4a78-9a5d-abeff82439af': '15:00',
    'f13580ac-8b75-43a9-a063-63030bb4c825': '15:30',
    '1059a4c8-fbc8-485e-a3c4-02c20e5808ce': '16:00',
    '86e0db3c-4c18-4958-91b6-479e3e24203a': '16:30',
    'e5195b0a-cd3b-408e-a2a0-b53324a05701': '17:00',
    '1fac0337-65c8-4dee-a9c0-a5810c679ab3': '17:30',
    '39e23286-1ddc-425a-9b74-8332ceecb359': '18:00',
    'd7537fe1-194d-4e80-b885-dbf7df71a0a1': '18:30',
    'f74be04c-2449-4f73-9817-2836bff213c5': '19:00',
    'a073871c-8735-4a15-aeab-133e42fec03e': '19:30',
    'f77da47b-a52b-4034-a167-a542d1a4a9f2': '20:00',
    'c0c1a11d-9191-438c-ba07-607aa5c41aba': '20:30',
    'cc7abbd5-8ac0-4ed5-897b-7537b2dcd7a3': '21:00',
    '1c8c6cf4-543a-4950-952f-0d01854ede61': '21:30',
    '58cc7292-ec9c-4349-a201-0467a06114c6': '22:00',
    'de03ac24-705d-40da-a888-47d907b0ae48': '22:30',
    '4b1dbd4d-08e3-4834-941a-c10fdd4927ad': '23:00',
    '00078575-8b05-4dfe-8d7a-2572956f5836': '23:30'
}
const timeOcOaIds: { [key: string]: string } = {
    '00:00': '3e0dde83-2abc-4f39-a57e-4d7b46f925ff',
    '00:30': 'efabcbc4-3c40-4f95-9eec-8f61e50a1a4e',
    '01:00': 'b490e0fb-b97e-41d8-b6ef-10935afeb4df',
    '01:30': 'd7228a94-ca30-4e8b-931a-6d5f1eced3d4',
    '02:00': '0f6d518d-1d5d-4731-9ba8-b2005d472e51',
    '02:30': 'bf393044-3db8-4f89-935c-2d0d3ed5ef54',
    '03:00': 'ff046641-8bf0-4574-8b1b-c0fe3192ac8e',
    '03:30': '0f516b82-fa5a-4e49-b7fd-00cbc4b83d5d',
    '04:00': '0bcbe4c8-4e90-4553-a98d-2a8afb91cd3e',
    '04:30': '978c8a70-3009-4360-aa4d-01d285d54606',
    '05:00': '64faa44b-4475-43d5-97d6-e2458eadcf95',
    '05:30': 'e16024fd-6237-4869-9d1a-e496191588dc',
    '06:00': '6ddfcbe1-caeb-43c6-bbe9-1faa5d25bd55',
    '06:30': '6347b6b3-1342-43d9-97b3-804adfe19fda',
    '07:00': 'eb2f9111-464e-468b-97ea-3c3e1aae51ab',
    '07:30': '54e31dbf-b4b0-4bc2-a32f-e084848c77a4',
    '08:00': 'f59a63a8-dde8-4452-8e67-aa17c4c97eca',
    '08:30': '68e73f5a-4482-4a19-acd8-2222eddf5d38',
    '09:00': '5d905404-700d-4780-84d9-29aa72b18695',
    '09:30': 'e95ab552-0866-40cb-9ab7-29ac5cc84e6d',
    '10:00': '32fd7ab6-f6cb-4d2d-a85d-9063967a0edb',
    '10:30': '4a196fc5-d305-4b4a-8a6e-41921f57919e',
    '11:00': '80846e45-f093-4cab-bb17-b621d0912b9b',
    '11:30': 'ce66429f-6228-4a1b-96b2-49ada30a4ac4',
    '12:00': '0638adcf-3975-4bec-b7e0-88b3612ae23e',
    '12:30': 'f12d806d-57fa-4f9f-8681-a1f401054c17',
    '13:00': '91a34649-1fa8-443e-b645-4300e65d7727',
    '13:30': '0d6ecd98-51c8-46ff-b9d8-d9a5798c5ada',
    '14:00': '06699bb2-2924-46a0-ad7a-6c451991c4eb',
    '14:30': '56c1ab59-a9dc-4458-b3af-30c9b0d146e6',
    '15:00': '66b7590c-e584-4a78-9a5d-abeff82439af',
    '15:30': 'f13580ac-8b75-43a9-a063-63030bb4c825',
    '16:00': '1059a4c8-fbc8-485e-a3c4-02c20e5808ce',
    '16:30': '86e0db3c-4c18-4958-91b6-479e3e24203a',
    '17:00': 'e5195b0a-cd3b-408e-a2a0-b53324a05701',
    '17:30': '1fac0337-65c8-4dee-a9c0-a5810c679ab3',
    '18:00': '39e23286-1ddc-425a-9b74-8332ceecb359',
    '18:30': 'd7537fe1-194d-4e80-b885-dbf7df71a0a1',
    '19:00': 'f74be04c-2449-4f73-9817-2836bff213c5',
    '19:30': 'a073871c-8735-4a15-aeab-133e42fec03e',
    '20:00': 'f77da47b-a52b-4034-a167-a542d1a4a9f2',
    '20:30': 'c0c1a11d-9191-438c-ba07-607aa5c41aba',
    '21:00': 'cc7abbd5-8ac0-4ed5-897b-7537b2dcd7a3',
    '21:30': '1c8c6cf4-543a-4950-952f-0d01854ede61',
    '22:00': '58cc7292-ec9c-4349-a201-0467a06114c6',
    '22:30': 'de03ac24-705d-40da-a888-47d907b0ae48',
    '23:00': '4b1dbd4d-08e3-4834-941a-c10fdd4927ad',
    '23:30': '00078575-8b05-4dfe-8d7a-2572956f5836'
}

export {
    videoOcId,
    videoOcOaIds,
    ocIdToIdLookupOa,
    memberOcId,
    memberOcOaIds,
    likedOcId,
    likedOcOaIds,
    watchedOcId,
    watchedOcOaIds,
    folderOcId,
    folderOcOaIds,
    bookmarkOcId,
    bookmarkOcOaIds,
    commentOcId,
    commentOcOaIds,
    shareOcId,
    shareOcOaIds,
    reminderOcId,
    reminderOcOaIds,
    timeOcOaIds,
    reminderOcFrequencyPicklistOptions,
    reminderOcTimePicklistOptions
}
